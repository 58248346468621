import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatureComponent } from './feature/feature.component';
import { VariantsComponent } from './variants/variants.component';
import { ColorsComponent } from './colors/colors.component';
import { FeatureShowcaseComponent } from './feature-showcase/feature-showcase.component';
import { IntroductionTutorialComponent } from './introduction-tutorial/introduction-tutorial.component';
import { MicrointeractionPanelComponent } from './microinteraction-panel/microinteraction-panel.component';
import { LhsBottomComponent } from './lhs-bottom/lhs-bottom.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { KitsComponent } from './kits/kits.component';
import { AccessoryListingComponent } from './accessory-listing/accessory-listing.component';
import { EmiComponent } from './summary/emi/emi.component';
import { SummaryComponent } from './summary/summary/summary.component';
import { ConfiguratorHeaderComponent } from './configurator-header/configurator-header.component';
import { ProfileComponent } from './profile/profile.component';
import { SaveSharePopupComponent } from './save-share-popup/save-share-popup.component';
import { LoginComponent } from './login/login.component';
import { InteriorExteriorButtonsComponent } from './interior-exterior-buttons/interior-exterior-buttons.component';
import { LoaderModalComponent } from './loader-modal/loader-modal.component';
import { BuildnameModalComponent } from './buildname-modal/buildname-modal.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SummaryBreakupComponent } from './summary/summary-breakup/summary-breakup.component';
import { KeyFeaturesModalComponent } from './key-features-modal/key-features-modal.component';
import { NetworkConnectivityComponent } from './network-connectivity/network-connectivity.component';
import { OrientationModalComponent } from './orientation-modal/orientation-modal.component';
import { BrowserSupportScreenComponent } from './browser-support-screen/browser-support-screen.component';
import { ExtendedWarrantyComponent } from './summary/extended-warranty/extended-warranty.component';
import { CarExchangeComponent } from './summary/car-exchange/car-exchange.component';
import { SharedPipesModule } from '../pipes/pipes.module';
import { AmcComponent } from './summary/amc/amc.component';
import { InsuranceComponent } from './summary/insurance/insurance.component';
import { PostcodeComponent } from './postcode/postcode.component';
import { SaveShareNewPopupComponent } from './save-share-new-popup/save-share-new-popup.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TrimsComponent } from './trims/trims.component';
import { ViewOffersPopupComponent } from './view-offers-popup/view-offers-popup.component';
import { TestDriveFormComponent } from './test-drive-form/test-drive-form.component';
import { FeedbackComponent } from './feedback/feedback.component';
@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        SharedPipesModule,
        TranslateModule
    ],
    declarations: [
        FeatureComponent,
        VariantsComponent,
        ColorsComponent,
        FeatureShowcaseComponent,
        IntroductionTutorialComponent,
        MicrointeractionPanelComponent,
        LhsBottomComponent,
        AccessoriesComponent,
        AddOnsComponent,
        ConfirmationModalComponent,
        KitsComponent,
        AccessoryListingComponent,
        EmiComponent,
        SummaryComponent,
        ConfiguratorHeaderComponent,
        ProfileComponent,
        SaveSharePopupComponent,
        LoginComponent,
        InteriorExteriorButtonsComponent,
        LoaderModalComponent,
        BuildnameModalComponent,
        TestDriveFormComponent, 
        PrivacyPolicyComponent,
        SummaryBreakupComponent,
        KeyFeaturesModalComponent,
        NetworkConnectivityComponent,
        OrientationModalComponent,
        BrowserSupportScreenComponent,
        ExtendedWarrantyComponent,
        CarExchangeComponent,
        AmcComponent,
        InsuranceComponent,
        PostcodeComponent,
        SaveShareNewPopupComponent,
        TrimsComponent,
        ViewOffersPopupComponent,
        FeedbackComponent
    ],
    exports: [
        FeatureComponent,
        VariantsComponent,
        ColorsComponent,
        FeatureShowcaseComponent,
        IntroductionTutorialComponent,
        MicrointeractionPanelComponent,
        LhsBottomComponent,
        AccessoriesComponent,
        AddOnsComponent,
        ConfirmationModalComponent,
        KitsComponent,
        AccessoryListingComponent,
        EmiComponent,
        SummaryComponent,
        ConfiguratorHeaderComponent,
        ProfileComponent,
        SaveSharePopupComponent,
        LoginComponent,
        InteriorExteriorButtonsComponent,
        LoaderModalComponent,
        BuildnameModalComponent,
        TestDriveFormComponent,
        PrivacyPolicyComponent,
        SummaryBreakupComponent,
        KeyFeaturesModalComponent,
        NetworkConnectivityComponent,
        OrientationModalComponent,
        BrowserSupportScreenComponent,
        ExtendedWarrantyComponent,
        CarExchangeComponent,
        AmcComponent,
        PostcodeComponent,
        SaveShareNewPopupComponent,
        TranslateModule,
        TrimsComponent,
        ViewOffersPopupComponent,
        FeedbackComponent
    ]
})
export class SharedComponentsModule {}
