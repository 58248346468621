/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { encode, decode } from 'ee-crypto-encode-decode';
import { environment } from 'src/environments/environment';
import { CachingService } from '../cache/caching.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global/global.service';
declare let window;
declare let gtag;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private isEncrypted = false;

  constructor(
    private httpClient: HttpClient,
    private cachingService: CachingService,
    private route: ActivatedRoute
  ) {}

  /**
   *
   * @returns
   * The code is trying to authenticate the user by sending them a token.
   * It's also using sessionStorage to store the access-token.
   */
  authenticate() {
    return new Promise((resolve, reject) => {
      const json = {
        username:
          'U2FsdGVkX18jrGBdGbjQy3Ox8Jr5gZEC1kH7jGwWj6uMFFbCcm69p+xgnlVHBQVD',
        password:
          'U2FsdGVkX1/3kh8Hh7wkZsy5aGdS01rW3C6nWfwuT4brgq46FiB8AU9Rf0zKO5vA',
        company_token:
          '38d66d9692ac590000a91b03a88da1c88d51fab2b78f63171f553ecc551a0c6f',
      };
      const reqBody = this.isEncrypted ? encode(json) : json;
      return this.httpClient
        .post(environment.apiUrl + 'api/v1/login ', reqBody)
        .toPromise()
        .then(
          (data: any) => {
            sessionStorage.setItem('access-token', data.response.token);
            resolve(true);
          },
          (error) => {}
        );
    });
  }

  /**
   *
   * @param token The code is trying to validate a token.
   * The code is sending the token and an encrypted body to the server.
   * If the server responds with success, then it will authenticate itself.
   * Otherwise, it will log any errors that occurred in its console and continue on as normal.
   * @returns
   */
  validateToken(token) {
    let reqBody = {
      token,
    };
    reqBody = this.isEncrypted ? encode(reqBody) : reqBody;
    return this.httpClient
      .post(environment.apiUrl + 'api/verify-token', reqBody)
      .toPromise()
      .then(
        (res: any) => {
          if (res.message !== 'success') {
            this.authenticate();
          }
        },
        (err) => {
          console.error('Error ', err);
        }
      );
  }
  /**
   * The code is a promise that will return the data from the JSON file.
   *
   * @param jsonName The Json to fetch
   * @returns
   */
  getDataFromJSON(jsonName: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any[]>(`assets/JSON/${jsonName}.json`, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            resolve(response.body);
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and
   * return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  post(endPoint: string, requestBody: any) {
    requestBody['lang_code'] = 'en-US';
    const request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + 'api/v1/' + endPoint, request, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response.body)));
            } else {
              resolve(response.body);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and
   * return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  put(endPoint: string, requestBody: any) {
    requestBody['lang_code'] = 'en-US';
    const request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .put<any>(environment.apiUrl + 'api/v1/' + endPoint, request, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response.body)));
            } else {
              resolve(response.body);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and
   * return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  get(endPoint: string, requestBody: any) {
    requestBody['lang_code'] = 'en-US';
    const request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(environment.apiUrl + 'api/v1/' + endPoint, {
          params: request,
        })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response)));
            } else {
              resolve(response);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * @param endpoint The code is a function that downloads the file from an endpoint.
   * @returns
   */
  download(endpoint) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };

    return this.httpClient.get(
      environment.apiUrl + 'api/v1/' + endpoint,
      httpOptions
    );
  }
  /**
   * The code is a function that takes in an event and then pushes the data to the data layer.
   * The code is called when there is an event on the window object.
   * It will be called when the user clicks on an event.
   *
   * @param event The first parameter of the function is an event object which contains information about what happened in the application.
   * @param eventCategory The second parameter is a string representing the category of events that this event falls under.
   * @param eventAction The third parameter is a string representing what action was taken by the user.
   * @param eventLabel The final parameter is a string with some text describing what happened to help identify why this event occurred.
   */
  logGAEvent(event, eventCategory, eventAction, eventLabel) {
    // if not mobile device & includes ::Set_ in string then don't push it to datalayer
    if (eventAction.includes('::Set_') && !this.isMobileDevice()) {
      return;
    } else {
      window.dataLayer.push({
        event,
        eventCategory,
        eventAction,
        eventLabel,
      });
    }
  }

  logFloodLightEvents(modelName: any) {
    try {
      console.log('Floodlight event triggered');
      gtag('event', 'conversion', {
        allow_custom_scripts: true,
        u1: '',
        u2: modelName,
        send_to: 'DC-6776099/fca_e0/pc_tt02a+standard',
      });
      console.log('Floodlight event triggered after gtag');
    } catch (error) {
      console.error('Error triggering Floodlight event:', error);
    }
  }
  
  async visualizerAnalytics(eventName, eventValue?) {
    const pinNo = await this.cachingService.getDataFromSession('pin_no');
    const model = await this.cachingService.getDataFromSession('modelSelected');
    const config_id = await this.cachingService.getDataFromSession('c_id');

    const request = {
      configuration_id: config_id || '',
      pin_no: (typeof pinNo === 'object' && model?.lms_integrated_code && pinNo?.[model.lms_integrated_code]) || '',
      action: eventName,
      value: eventValue ? eventValue : 'Yes',
    };
    this.post('configuration/journey', request)
      .then((res) => { })
      .catch((err) => {
        console.error('Error ', err);
      });
  }

  isMobileDevice() {
    const ua = navigator.userAgent;
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  sendReportingData(data) {
    window.sendData(data);
  }
  updateReportingData(key, value) {
    window.updatePayloadParameter(key, value);
  }
  updateMultipleReportingData(data) {
    window.updateMultiplePayloadParameter(data);
  }

  getResultantVariable(flag: boolean): string {
    const url = new URL(window.location.href);
    const hasCcTrue = url.searchParams.get('cc') === 'true';
    const result =
      window.location.href.includes('visualizer') && !hasCcTrue
        ? 'Visualizer'
        : 'Configurator';
    return `${result}_${flag ? 'Button' : 'Nudges'}`;
  }
}
