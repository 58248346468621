import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() msg: any;
  confirmation: boolean;
  constructor(public globalService: GlobalService,) {}

  ngOnInit() {}
  cancel() {
    // const message = JSON.stringify({
    //   iframeComponent: 'Car Exchange',
    //   response: 'success',
    // });
    // window.parent.postMessage(message, '*');
    this.confirmation = false;
    this.globalService.dismissModal(this.confirmation);
  }
  continue() {
    this.confirmation = true;
    this.globalService.dismissModal(this.confirmation);
  }
}
